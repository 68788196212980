<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <q-btn v-if="editable&&popupParam.eduEducationId" class="custom-btn" label="QR 코드" icon="arrow_circle_down" color="black" size="md">
            <q-popup-proxy>
              <c-card title="QR 코드 출력" class="cardClassDetailForm">
                <template slot="card-button">
                  <c-btn label="" icon="print" @btnClicked="print" />
                </template>
                <template slot="card-detail">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div class="wrap" action="#" id="printJS-form">
                        <vue-qrcode
                          :value="serverName+'/sop/edu/result/educationResult?eduEducationId='
                          + popupParam.eduEducationId" 
                          :options="{ width: 180 }"
                          class="canvas"
                        >
                        </vue-qrcode>
                        <span class="facilityStyle">
                          <p class="abc" >
                            <b>{{'-교육명 : '}}</b>{{educationInfo.educationName}}
                            <br>
                            <b>{{'-교육일시 : '}} </b>{{educationInfo.educationDate}}
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </c-card>
            </q-popup-proxy>
          </q-btn>
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveEducation"
            @btnCallback="saveCallback" 
          />
          <c-btn v-show="editable&&popupParam.eduEducationId&&!disabled" label="삭제" :editable="editable" icon="delete_forever" @btnClicked="remove" />
          <c-btn v-show="editable&&popupParam.eduEducationId&&!disabled" :showLoading="false" label="완료" icon="save" color="blue"  @btnClicked="resultComplete" />
          <c-btn v-show="editable&&disabled" label="교육결과서" icon="print" @btnClicked="btnResultReport" />
          <c-btn v-show="editable&&disabled" label="대상자 연명부" icon="print" @btnClicked="btnResultUsers" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            :required="true" 
            :editable="editable" 
            :changeItem="changeItem"
            :disabled="disabled"
            type="edit" 
            name="plantCd" 
            v-model="educationInfo.plantCd" />
        </div>
        <!-- <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-vendor
            :editable="editable"
            :required="true" 
            :changeItem="changeItem"
            :plantCd="educationInfo.plantCd"
            :disabled="disabled"
            label="업체"
            name="departmentDeptCd"
            v-model="educationInfo.departmentDeptCd">
          </c-vendor>
        </div> -->
        <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            required
            :afterIcon="disabled && popupParam.eduEducationId ? null :[
              { name: 'search', click: true, callbackName: 'searchEduMaster' },
              { name: 'close', click: true, callbackName: 'removeEduMaster'}
            ]"
            :readonly="true"
            :editable="editable"
            label="교육과정"
            name="educationCourseName"
            @searchEduMaster="searchEduMaster"
            @removeEduMaster="removeEduMaster"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :disabled="disabled"
            :editable="editable"
            label="교육명"
            name="educationName"
            v-model="educationInfo.educationName">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="disabled"
            :editable="editable"
            :required="true" 
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :disabled="disabled"
            :editable="editable"
            :isFlag="true"
            label="기간,일"
            trueLabel="기간"
            falseLabel="일"
            name="educationRangeFlag"
            v-model="educationInfo.educationRangeFlag"
            @datachange="datachange"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            v-show="isDate"
            :required="isDate"
            :disabled="disabled"
            :editable="editable"
            label="교육기간"
            :range="true"
            type="date"
            name="educationDate"
            v-model="educationDate">
          </c-datepicker>
          <c-datepicker
            v-show="!isDate"
            :required="!isDate"
            :disabled="disabled"
            :editable="editable"
            label="교육일"
            type="date"
            name="educationDate2"
            v-model="educationDate2">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :disabled="disabled"
            :editable="editable"
            label="교육시간"
            type="time"
            :range="true"
            :minuteStep="10"
            name="educationTime"
            v-model="educationTime">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :disabled="disabled"
            :editable="editable"
            label="교육장소"
            name="educationLocation"
            v-model="educationInfo.educationLocation">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="disabled"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_METHOD_CD"
            itemText="codeName"
            itemValue="code"
            name="educationMethodCd"
            label="교육방법"
            v-model="educationInfo.educationMethodCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            suffix="원"
            type="number"
            :editable="editable"
            :disabled="disabled"
            label="예상 계획"
            name="estimatedEducationExpenses"
            v-model="educationInfo.estimatedEducationExpenses">
          </c-text>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :editable="editable"
            :disabled="true"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :disabled="true"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="교육주기"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1">
          <c-checkbox
            :disabled="true"
            :editable="editable"
            :isFlag="true"
            label="법정교육 여부"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag">
          </c-checkbox>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <c-multi-select
            :disabled="true"
            :editable="editable"
            codeGroupCd="REGULATION_BILL_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-text
            :disabled="true"
            :editable="editable"
            label="주요 대상자"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            :disabled="true"
            :editable="editable"
            :rows="1"
            label="학습목적"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-textarea>
        </div>
      </template>
    </c-card>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-card title="결과정보" class="cardClassDetailForm">
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-text
                :afterIcon="disabled ? null :[
                  { name: 'search', click: true, callbackName: 'searchAssess'},
                  { name: 'close', click: true, callbackName: 'removeAssess' }
                ]"
                :close="false"
                :readonly="true"
                :editable="editable"
                :detail="true"
                label="위험성평가 결과"
                name="assessmentName"
                @searchAssess="searchAssess"
                @detailPop="detailAssess"
                @removeAssess="removeAssess"
                v-model="educationInfo.assessmentName">
              </c-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
              <c-textarea
                :disabled="disabled"
                :editable="editable" 
                name="educationOverview"
                label="교육총평" 
                v-model="educationInfo.educationOverview" />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <c-field
                type="user"
                :editable="editable"
                :disabled="disabled"
                :isUserAll="true"
                label="평가자"
                name="instructorInformation"
                v-model="educationInfo.instructorInformation">
              </c-field>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <c-text
                suffix="원"
                type="number"
                :editable="editable"
                :disabled="disabled"
                label="집행예산"
                name="executionBudget"
                v-model="educationInfo.executionBudget">
              </c-text>
            </div>
            <!-- <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <c-text
                suffix="원"
                type="number"
                :editable="editable"
                :disabled="true"
                label="예산잔액"
                name="budgetPlan"
                v-model="budgetPlan">
              </c-text>
            </div> -->
            <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2">
              <c-select
                :editable="editable"
                :disabled="disabled"
                type="edit"
                codeGroupCd="EDU_EVAL_CD"
                itemText="codeName"
                itemValue="code"
                name="evaluationEvalCd"
                label="교육평가"
                v-model="educationInfo.evaluationEvalCd"
              ></c-select>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="table"
          title="교육내용"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="educationInfo.eduSubjectList"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
          noDataLabel="교육내용이 없습니다."
          :editable="editable && !disabled"
          rowKey="subjectNo"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && !disabled" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
              <c-btn v-if="editable && !disabled && educationInfo.eduSubjectList.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removeItem" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-card title="교육교재(교육과정 관련)">
          <template slot="card-detail">
            <div class="col-12">
              <c-upload 
                :attachInfo="attachInfo"
                :editable="false">
              </c-upload>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table2"
          title="교육대상자"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :data="educationInfo.eduAttendeeList"
          :filtering="false"
          :checkClickFlag="false"
          :columnSetting="false"
          :usePaging="false"
          :merge="grid2.merge"
          :isExcelDown="false"
          :hideBottom="true"
          noDataLabel="교육대상자가 없습니다."
          :editable="editable && !disabled"
          rowKey="attendeesNo"
          selection="multiple"
        >
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn
                v-if="editable && !disabled"
                :showLoading="false"
                label="일용직추가"
                icon="add"
                @btnClicked="addAttendee1"
              />
              <c-btn
                v-if="editable && !disabled"
                :showLoading="false"
                label="사용자추가"
                icon="add"
                @btnClicked="addAttendee2"
              />
              <c-btn v-if="editable && !disabled && educationInfo.eduAttendeeList.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removeItem2" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'click'">
              <q-btn 
                round unelevated 
                size="10px"
                color="amber" 
                icon="search"
                @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                >
                <q-popup-proxy :ref="'proxy1_' + props.rowIndex" :breakpoint="400">
                  <component
                    :is="col.component"
                    :popupParam="props.row"
                    :rowIndex="props.rowIndex"
                    :disabled="disabled"
                    @callback="callback"
                  />
                </q-popup-proxy>
              </q-btn>
            </template>
          </template>
        </c-table>
      </div>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>
<script>
import { uid } from 'quasar'
import printJs from 'print-js'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-detail',
  components: {
    VueQrcode
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      educationInfo: {
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        estimatedEducationExpenses: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        educationStartTime: '',
        educationEndTime: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',
        educationRangeFlag: 'Y',
        companyCd: '',

        riskAssessmentPlanId: '',
        assessmentName: '',
        stepCd: '',

        eduSubjectList: [], // 과정별 교육과목
        deleteEduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
        deleteEduAttendeeList: [],
      },
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'EDU_CURRICULUM',
        taskKey: '',
      },
      grid: {
        columns: [
          {
            name: 'subjectName',
            field: 'subjectName',
            label: '과목 및 세부내용',
            align: 'left',
            style: 'width:45%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'educationTime',
            field: 'educationTime',
            label: '교육시간',
            type: 'datetime',
            style: 'width:10%',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            type: 'text',
            label: '강사명',
            style: 'width:45%',
            align: 'left',
            sortable: false
          },
        ],
        height: '260px'
      },
      grid2: {
        columns: [
          {
            name: "deptName",
            field: "deptName",
            label: "업체",
            style: 'width:140px',
            align: "center",
            sortable: false,
          },
          {
            name: 'attendeesName',
            field: 'attendeesName',
            label: '대상자명',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: "attendeesPositionName",
            field: "attendeesPositionName",
            label: "직위",
            style: 'width:120px',
            align: "center",
            sortable: false,
          },
          {
            name: 'checkCompleteFlag',
            field: 'checkCompleteFlag',
            label: '이수여부',
            type: 'select',
            setHeader: true,
            style: 'width:100px',
            align: 'center',
            comboItems: [
              { code: 'Y', codeName: '이수' },
              { code: 'N', codeName: '미이수' },
            ],
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            component: () => import(`${'./signaturePop.vue'}`)
          },
          {
            name: 'electronSignatureFlag',
            field: 'electronSignatureFlag',
            label: '서명여부',
            style: 'width:80px',
            align: 'center',
            sortable: false,
          },
          // {
          //   name: 'electronSignature',
          //   field: 'electronSignature',
          //   label: '전자서명',
          //   style: 'width:150px',
          //   type: 'custom',
          //   align: 'center',
          //   sortable: false,
          // },
          {
            name: 'evaluationPoint',
            field: 'evaluationPoint',
            label: '평가점수',
            type: 'number',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'evaluationContent',
            field: 'evaluationContent',
            label: '평가내용',
            type: 'text',
            style: 'width:220px',
            align: 'left',
            sortable: false
          },
          {
            name: 'evaluationDate',
            field: 'evaluationDate',
            setHeader: true,
            label: '평가일',
            type: 'date',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'attendanceTime',
            field: 'attendanceTime',
            setHeader: true,
            label: '총 교육시간',
            style: 'width:100px',
            type: 'datetime',
            align: 'center',
            sortable: false
          },
          {
            name: 'noAttendanceReason',
            field: 'noAttendanceReason',
            label: '불참사유',
            type: 'text',
            style: 'width:260px',
            align: 'left',
            sortable: false
          },
          {
            name: 'opinionContent',
            field: 'opinionContent',
            label: '의견',
            style: 'width:200px',
            type: 'text',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '350px'
      },
      title: '',
      
      selectDate: '',
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveUrl: 'transactionConfig.sop.edu.result.insert.url',
      saveType: 'POST',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      completeUrl: '',
      changeItem: {
        plant: '',
        process: '',
        vendor: '',
      },
      serverName: '',
      educationDate2: '',
      newDate: ['', ''],
      newTime: ['', ''],
    };
    
  },
  computed: {
    disabled() {
      return Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd !== 'ESC000001'
    },
    isDate() {
      return this.educationInfo.educationRangeFlag === 'Y'
    },
    educationDate: {
      get() {
        if (this.educationInfo.educationStartDate && this.educationInfo.educationEndDate) {
          return [this.educationInfo.educationStartDate, this.educationInfo.educationEndDate]
        } else {
          return this.newDate;
        }
      },
      set(newDate) {
        if (newDate && newDate.length > 0) {
          this.educationDate[0] = newDate[0]
          this.educationDate[1] = newDate[1]
        } 
        this.newDate = newDate;
      }
    },
    educationTime: {
      get() {
        if (this.educationInfo.educationStartTime && this.educationInfo.educationEndTime) {
          return [this.educationInfo.educationStartTime, this.educationInfo.educationEndTime]
        } else {
          return this.newTime;
        }
      },
      set(newTime) {
        if (newTime && newTime.length > 0) {
          this.educationTime[0] = newTime[0]
          this.educationTime[1] = newTime[1]
        }
        this.newTime = newTime;
      }
    }
    
  },
  /* eslint-disable no-unused-vars */
  watch: {
    'educationInfo'() {
      this.$emit('transInfo', this.educationInfo);
    },
    'educationInfo.executionBudget'() {
      this.budgetPlan = Number(this.educationInfo.estimatedEducationExpenses) - Number(this.educationInfo.executionBudget)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.redirectUrl = selectConfig.sai.tbm.qrUrl.url;
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.insertUrl = transactionConfig.sop.edu.result.insert.url;
      this.updateUrl = transactionConfig.sop.edu.result.update.url;
      this.deleteUrl = transactionConfig.sop.edu.result.delete.url;
      this.completeUrl = transactionConfig.sop.edu.result.complete.url;
      this.printUrl = selectConfig.sop.edu.result.print.first.url;
      this.printUrl2 = selectConfig.sop.edu.result.print.second.url;
      // list setting
      this.getDetail();
      this.serverUrl();
    },
    print() {
      printJs({
        printable: 'printJS-form',
        type: 'html',
        font_size: '15px',
        targetStyles: ['*'],
      })
    },
    serverUrl() {
      this.$http.url = this.redirectUrl;
      this.$http.type = 'GET';
      this.$http.param = {};
      this.$http.request(
        _result => {
          this.serverName = _result.data
        },
        _error => {
          window.getApp.$emit('APP_REQUEST_ERROR', _error);
        }
      );
    },
    setTaskKey() {
      let taskKey = this.educationInfo.eduCourseId ? this.educationInfo.eduCourseId : '';
      this.attachInfo.taskKey = taskKey
    },
    getDetail() {
      if (this.popupParam.eduEducationId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.eduEducationId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = _result.data;

          if (_result.data.educationStartDate && _result.data.educationEndDate) {
            this.educationDate = [_result.data.educationStartDate, _result.data.educationEndDate]
          }
          if (_result.data.educationStartTime && _result.data.educationEndTime) {
            this.educationTime = [_result.data.educationStartTime, _result.data.educationEndTime]
          }

          if (this.educationInfo.educationRangeFlag !== 'Y') {
            this.educationDate2 = this.educationInfo.educationStartDate
          }
          this.setTaskKey();
        },);
      }
    },
    saveEducation() {
      if (this.popupParam.eduEducationId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
        this.educationInfo.companyCd = this.$store.getters.user.companyCd;
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.educationInfo.documentStatusCd = 'ESC000001'
              this.educationInfo.regUserId = this.$store.getters.user.userId
              this.educationInfo.chgUserId = this.$store.getters.user.userId
              if (this.educationInfo.educationRangeFlag === 'Y') {
                if (this.educationDate && this.educationDate.length > 0) {
                  this.educationInfo.educationStartDate = this.educationDate[0];
                  this.educationInfo.educationEndDate = this.educationDate[1];
                }
              } else {
                this.educationInfo.educationStartDate = this.educationDate2;
                this.educationInfo.educationEndDate = this.educationDate2;
              }
              if (this.educationTime && this.educationTime.length > 0) {
                this.educationInfo.educationStartTime = this.educationTime[0];
                this.educationInfo.educationEndTime = this.educationTime[1];
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.eduEducationId = result.data;
      this.getDetail();
    },
    addItem() {
      this.educationInfo.eduSubjectList.push({
        eduCourseId: this.eduCourseId,
        subjectNo: uid(),
        subjectName: '', // 교육명
        subjectDetailName: '', // 세부내용
        educationTime: '', // 교육시간
        instructorName: '', // 강사명
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.educationInfo.deleteEduSubjectList) {
            this.educationInfo.deleteEduSubjectList = [];
          }
          if (
            this.$_.findIndex(this.educationInfo.deleteEduSubjectList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.educationInfo.deleteEduSubjectList.push(item);
          }
          this.educationInfo.eduSubjectList = this.$_.reject(
            this.educationInfo.eduSubjectList,
            item
          );
        });
      }
    },
    removeItem2() {
      let selectData = this.$refs['table2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.educationInfo.deleteEduAttendeeList) {
            this.educationInfo.deleteEduAttendeeList = [];
          }
          if (
            this.$_.findIndex(this.educationInfo.deleteEduAttendeeList, {
              tbmRiskId: item.tbmRiskId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.educationInfo.deleteEduAttendeeList.push(item);
          }
          this.educationInfo.eduAttendeeList = this.$_.reject(
            this.educationInfo.eduAttendeeList,
            item
          );
        });
      }
    },
    searchEduMaster() {
      this.popupOptions.title = '교육과정 검색'; // 교육과정 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/educationCurriculumnPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEduCoursePopup;
    },
    closeEduCoursePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data.selectData && data.selectData.length > 0) {
        this.$http.url = this.$format(selectConfig.sop.edu.course.get.url, data.selectData[0].eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          // 빈값들 filter
          this.educationInfo.educationCourseName = _result.data.educationCourseName, // 교육과정명
          this.educationInfo.educationTypeCd = _result.data.educationTypeCd, // 교육구분
          // 교육과정 마스터 데이터 
          this.educationInfo.eduCourseId = _result.data.eduCourseId, // 교육과정 key
          this.educationInfo.educationCycle = _result.data.educationCycle, // 교육주기
          this.educationInfo.educationKindCdLarge = _result.data.educationKindCdLarge, // 교육종류 대
          this.educationInfo.educationKindCdSmall = _result.data.educationKindCdSmall, // 교육종류 소
          this.educationInfo.educationPurpose = _result.data.educationPurpose, // 학습목적
          this.educationInfo.legalEducationFlag = _result.data.legalEducationFlag, // 법정여부
          this.educationInfo.mainTargetAudience = _result.data.mainTargetAudience, // 주요대상자
          this.educationInfo.relationLaws = _result.data.relationLaws, // 관련법규
          this.educationInfo.educationTime = _result.data.educationTime // 교육시간
          this.educationInfo.estimatedEducationExpenses = _result.data.estimatedEducationExpenses // 교육시간
          this.educationInfo.eduSubjectList = _result.data.eduCourseSubjectList;

          this.$_.forEach(this.educationInfo.eduSubjectList, _item => {
            _item.editFlag = 'C'
          })


          this.setTaskKey();
        },);
      }
    },
    linkClick() {
      this.popupOptions.title = '교육과정 상세'; // 교육과정 상세
      this.popupOptions.param = {};
      this.popupOptions.target = () => import(`${'@/pages/sop/edu/cc/educationCurriculumDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLinkPopup;
    },
    closeLinkPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    
    removeEduMaster() {
      this.educationInfo = {
        eduEducationId: '',
        eduCourseId: '',
        educationYear: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        legalEducationFlag: null,
        educationTime: '',
        educationCycle: null,
        educationPurpose: '',
        relationLaws: '',
        mainTargetAudience: '',
        educationMonth: null,
        educationLocation: '',
        budgetPlan: '',
        executionBudget: '',
        educationStartDate: '',
        educationEndDate: '',
        instructorInformation: '',
        sopMocId: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: '',
        documentStatusCd: '',
        qrCodeRoute: '',

        eduSubjectList: [],
      }
    },
    addAttendee1() {
      this.popupOptions.title = "일용직 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        isDayJobAll: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "50%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendeePopup1;
    },
    // addAttendee1() {
    //   this.popupOptions.title = "일용직 검색"; // TBM 참석자 검색
    //   this.popupOptions.param = {
    //     tbmId: this.popupParam.tbmId,
    //   };
    //   this.popupOptions.target = () => import(`${"./tbmDayJobAttendeePop.vue"}`);
    //   this.popupOptions.width = "60%";
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeAttendeePopup1;
    // },
    closeAttendeePopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.educationInfo.eduAttendeeList.push({
              eduEducationId: this.popupParam.eduEducationId,
              deptName: _item.deptName,
              attendeesNo: uid(),
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              attendeesPositionName: '일용직',
              checkCompleteFlag: null,
              electronSignature: '',
              inOutsideTypeCd: null,
              evaluationPoint: '',
              evaluationContent: '',
              evaluationDate: '',
              attendanceTime: '',
              noAttendanceReason: '',
              checkCompleteFlagName: '미이수',
              electronSignatureFlag: '미서명',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
      }
    },
    addAttendee2() {
      this.popupOptions.title = "사용자 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.educationInfo.plantCd,
        isAllVendor: true,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "50%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendeePopup2;
    },
    closeAttendeePopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.educationInfo.eduAttendeeList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.educationInfo.eduAttendeeList.push({
              eduEducationId: this.popupParam.eduEducationId,
              deptName: _item.deptName,
              attendeesNo: uid(),
              attendeesId: _item.userId,
              attendeesName: _item.userName,
              attendeesPositionName: _item.jobName,
              checkCompleteFlag: null,
              electronSignature: '',
              inOutsideTypeCd: null,
              evaluationPoint: '',
              evaluationContent: '',
              evaluationDate: '',
              attendanceTime: '',
              noAttendanceReason: '',
              checkCompleteFlagName: '미이수',
              electronSignatureFlag: '미서명',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            })
          }
        })
      }
    },
    datachange() {
      this.educationInfo.educationStartDate = '';
      this.educationInfo.educationEndDate = '';
      if (this.educationInfo.educationRangeFlag === 'Y') {
        this.educationDate2 = '';
      } else {
        this.educationDate = ['', ''];
      }
    },
    callback(data, rowIndex) {
      this.educationInfo.eduAttendeeList[rowIndex].electronSignature = data
      this.educationInfo.eduAttendeeList[rowIndex].electronSignatureFlag = '서명'
      let refName = 'proxy1_' + rowIndex;
      this.$refs[refName].hide();
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.eduEducationId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    resultComplete() {
      let flag = false;
      if (!flag) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '결과완료하시겠습니까?(저장과 함께 처리됩니다.)',
          // TODO : 점검완료 하시겠습니까?
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.educationInfo.documentStatusCd = 'ESC000010'
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = this.educationInfo;
            this.$http.request(() => {
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
      });
      }
    },
    btnResultReport() {
      let thisVue = this;
      this.$http.url = this.printUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: this.popupParam.eduEducationId
      }
      this.$http.request(
        _result => {
          let fileOrgNm = this.educationInfo.educationName + ' 결과서.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    btnResultUsers() {
      let thisVue = this;
      this.$http.url = this.printUrl2;
      this.$http.type = 'GET';
      this.$http.param = {
        eduEducationId: this.popupParam.eduEducationId
      }
      this.$http.request(
        _result => {
          let fileOrgNm = this.educationInfo.educationName + ' 연명부.docx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    searchAssess() {
      this.popupOptions.title = '위험성평가 결과 검색'; 
      this.popupOptions.param = {
        type: 'single',
        plantCd: this.educationInfo.plantCd
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/riskAssessmentPop.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAssessPopup;
    },
    closeAssessPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.educationInfo.assessmentName =  data[0].assessmentName
        this.educationInfo.riskAssessmentPlanId = data[0].riskAssessmentPlanId
        this.educationInfo.ramStepCd = data[0].ramStepCd
      }
    },
    detailAssess() {
      if (!this.educationInfo.riskAssessmentPlanId) return;
      let title = '';
      this.popupOptions.title = title + ' 상세'; 
      this.popupOptions.param = {
        riskAssessmentPlanId: this.educationInfo.riskAssessmentPlanId,
        stepCd: this.educationInfo.stepCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/saas/riskAssessDetail.vue'}`);
      this.popupOptions.width = '90%';
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeLinkPopup;
    },
    removeAssess() {
      this.educationInfo.assessmentName = ''
      this.educationInfo.riskAssessmentPlanId = ''
    },
  }
};
</script>
<style>
.canvas {
  width: 100%;
  height: 100%;
  margin: 3 auto;
  display: inline;
}
.wrap {
  display:flex;
  flex-wrap:wrap;
  padding:20px;
  height:400px;
  box-sizing:border-box;
  border-color: #aaa;
}
.facilityStyle {
  height:200px;
  width:300px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-wrap:wrap;
}
.abc {
  width:100%;
  height:20px;
  display:inline;
}
</style>