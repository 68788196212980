var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        { staticClass: "cardClassDetailForm", attrs: { title: "기본정보" } },
        [
          _c(
            "template",
            { slot: "card-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && _vm.popupParam.eduEducationId
                    ? _c(
                        "q-btn",
                        {
                          staticClass: "custom-btn",
                          attrs: {
                            label: "QR 코드",
                            icon: "arrow_circle_down",
                            color: "black",
                            size: "md",
                          },
                        },
                        [
                          _c(
                            "q-popup-proxy",
                            [
                              _c(
                                "c-card",
                                {
                                  staticClass: "cardClassDetailForm",
                                  attrs: { title: "QR 코드 출력" },
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "card-button" },
                                    [
                                      _c("c-btn", {
                                        attrs: { label: "", icon: "print" },
                                        on: { btnClicked: _vm.print },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("template", { slot: "card-detail" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "wrap",
                                              attrs: {
                                                action: "#",
                                                id: "printJS-form",
                                              },
                                            },
                                            [
                                              _c("vue-qrcode", {
                                                staticClass: "canvas",
                                                attrs: {
                                                  value:
                                                    _vm.serverName +
                                                    "/sop/edu/result/educationResult?eduEducationId=" +
                                                    _vm.popupParam
                                                      .eduEducationId,
                                                  options: { width: 180 },
                                                },
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "facilityStyle",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    { staticClass: "abc" },
                                                    [
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s("-교육명 : ")
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.educationInfo
                                                            .educationName
                                                        ) + " "
                                                      ),
                                                      _c("br"),
                                                      _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            "-교육일시 : "
                                                          ) + " "
                                                        ),
                                                      ]),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.educationInfo
                                                            .educationDate
                                                        ) + " "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.educationInfo,
                          mappingType: _vm.saveType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveEducation,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editable &&
                          _vm.popupParam.eduEducationId &&
                          !_vm.disabled,
                        expression:
                          "editable&&popupParam.eduEducationId&&!disabled",
                      },
                    ],
                    attrs: {
                      label: "삭제",
                      editable: _vm.editable,
                      icon: "delete_forever",
                    },
                    on: { btnClicked: _vm.remove },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editable &&
                          _vm.popupParam.eduEducationId &&
                          !_vm.disabled,
                        expression:
                          "editable&&popupParam.eduEducationId&&!disabled",
                      },
                    ],
                    attrs: {
                      showLoading: false,
                      label: "완료",
                      icon: "save",
                      color: "blue",
                    },
                    on: { btnClicked: _vm.resultComplete },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && _vm.disabled,
                        expression: "editable&&disabled",
                      },
                    ],
                    attrs: { label: "교육결과서", icon: "print" },
                    on: { btnClicked: _vm.btnResultReport },
                  }),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.editable && _vm.disabled,
                        expression: "editable&&disabled",
                      },
                    ],
                    attrs: { label: "대상자 연명부", icon: "print" },
                    on: { btnClicked: _vm.btnResultUsers },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-plant", {
                  attrs: {
                    required: true,
                    editable: _vm.editable,
                    changeItem: _vm.changeItem,
                    disabled: _vm.disabled,
                    type: "edit",
                    name: "plantCd",
                  },
                  model: {
                    value: _vm.educationInfo.plantCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "plantCd", $$v)
                    },
                    expression: "educationInfo.plantCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    afterIcon:
                      _vm.disabled && _vm.popupParam.eduEducationId
                        ? null
                        : [
                            {
                              name: "search",
                              click: true,
                              callbackName: "searchEduMaster",
                            },
                            {
                              name: "close",
                              click: true,
                              callbackName: "removeEduMaster",
                            },
                          ],
                    readonly: true,
                    editable: _vm.editable,
                    label: "교육과정",
                    name: "educationCourseName",
                  },
                  on: {
                    searchEduMaster: _vm.searchEduMaster,
                    removeEduMaster: _vm.removeEduMaster,
                  },
                  model: {
                    value: _vm.educationInfo.educationCourseName,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationCourseName", $$v)
                    },
                    expression: "educationInfo.educationCourseName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    required: "",
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "교육명",
                    name: "educationName",
                  },
                  model: {
                    value: _vm.educationInfo.educationName,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationName", $$v)
                    },
                    expression: "educationInfo.educationName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    required: true,
                    type: "edit",
                    codeGroupCd: "EDU_CLASS_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "educationTypeCd",
                    label: "교육구분",
                  },
                  model: {
                    value: _vm.educationInfo.educationTypeCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationTypeCd", $$v)
                    },
                    expression: "educationInfo.educationTypeCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    isFlag: true,
                    label: "기간,일",
                    trueLabel: "기간",
                    falseLabel: "일",
                    name: "educationRangeFlag",
                  },
                  on: { datachange: _vm.datachange },
                  model: {
                    value: _vm.educationInfo.educationRangeFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationRangeFlag", $$v)
                    },
                    expression: "educationInfo.educationRangeFlag",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isDate,
                      expression: "isDate",
                    },
                  ],
                  attrs: {
                    required: _vm.isDate,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "교육기간",
                    range: true,
                    type: "date",
                    name: "educationDate",
                  },
                  model: {
                    value: _vm.educationDate,
                    callback: function ($$v) {
                      _vm.educationDate = $$v
                    },
                    expression: "educationDate",
                  },
                }),
                _c("c-datepicker", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isDate,
                      expression: "!isDate",
                    },
                  ],
                  attrs: {
                    required: !_vm.isDate,
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "교육일",
                    type: "date",
                    name: "educationDate2",
                  },
                  model: {
                    value: _vm.educationDate2,
                    callback: function ($$v) {
                      _vm.educationDate2 = $$v
                    },
                    expression: "educationDate2",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-datepicker", {
                  attrs: {
                    required: "",
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "교육시간",
                    type: "time",
                    range: true,
                    minuteStep: 10,
                    name: "educationTime",
                  },
                  model: {
                    value: _vm.educationTime,
                    callback: function ($$v) {
                      _vm.educationTime = $$v
                    },
                    expression: "educationTime",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    label: "교육장소",
                    name: "educationLocation",
                  },
                  model: {
                    value: _vm.educationInfo.educationLocation,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationLocation", $$v)
                    },
                    expression: "educationInfo.educationLocation",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: _vm.disabled,
                    editable: _vm.editable,
                    type: "edit",
                    codeGroupCd: "EDU_METHOD_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "educationMethodCd",
                    label: "교육방법",
                  },
                  model: {
                    value: _vm.educationInfo.educationMethodCd,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationMethodCd", $$v)
                    },
                    expression: "educationInfo.educationMethodCd",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-text", {
                  attrs: {
                    suffix: "원",
                    type: "number",
                    editable: _vm.editable,
                    disabled: _vm.disabled,
                    label: "예상 계획",
                    name: "estimatedEducationExpenses",
                  },
                  model: {
                    value: _vm.educationInfo.estimatedEducationExpenses,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.educationInfo,
                        "estimatedEducationExpenses",
                        $$v
                      )
                    },
                    expression: "educationInfo.estimatedEducationExpenses",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    editable: _vm.editable,
                    disabled: true,
                    type: "edit",
                    codeGroupCd: "EDU_KIND_FST_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "educationKindCdLarge",
                    label: "교육종류1",
                  },
                  model: {
                    value: _vm.educationInfo.educationKindCdLarge,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationKindCdLarge", $$v)
                    },
                    expression: "educationInfo.educationKindCdLarge",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    type: "edit",
                    codeGroupCd: "EDU_KIND_SEC_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "educationKindCdSmall",
                    label: "교육종류2",
                  },
                  model: {
                    value: _vm.educationInfo.educationKindCdSmall,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationKindCdSmall", $$v)
                    },
                    expression: "educationInfo.educationKindCdSmall",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" },
              [
                _c("c-select", {
                  attrs: {
                    disabled: true,
                    type: "edit",
                    codeGroupCd: "EDU_CYCLE_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "educationCycle",
                    label: "교육주기",
                  },
                  model: {
                    value: _vm.educationInfo.educationCycle,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationCycle", $$v)
                    },
                    expression: "educationInfo.educationCycle",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" },
              [
                _c("c-checkbox", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    isFlag: true,
                    label: "법정교육 여부",
                    name: "legalEducationFlag",
                  },
                  model: {
                    value: _vm.educationInfo.legalEducationFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "legalEducationFlag", $$v)
                    },
                    expression: "educationInfo.legalEducationFlag",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" },
              [
                _c("c-multi-select", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    codeGroupCd: "REGULATION_BILL_CD",
                    itemText: "codeName",
                    itemValue: "code",
                    label: "관련 법규",
                    name: "relationLaws",
                    maxValues: "3",
                  },
                  model: {
                    value: _vm.educationInfo.relationLaws,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "relationLaws", $$v)
                    },
                    expression: "educationInfo.relationLaws",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-text", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    label: "주요 대상자",
                    name: "mainTargetAudience",
                  },
                  model: {
                    value: _vm.educationInfo.mainTargetAudience,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "mainTargetAudience", $$v)
                    },
                    expression: "educationInfo.mainTargetAudience",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: true,
                    editable: _vm.editable,
                    rows: 1,
                    label: "학습목적",
                    name: "educationPurpose",
                  },
                  model: {
                    value: _vm.educationInfo.educationPurpose,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "educationPurpose", $$v)
                    },
                    expression: "educationInfo.educationPurpose",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "결과정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          afterIcon: _vm.disabled
                            ? null
                            : [
                                {
                                  name: "search",
                                  click: true,
                                  callbackName: "searchAssess",
                                },
                                {
                                  name: "close",
                                  click: true,
                                  callbackName: "removeAssess",
                                },
                              ],
                          close: false,
                          readonly: true,
                          editable: _vm.editable,
                          detail: true,
                          label: "위험성평가 결과",
                          name: "assessmentName",
                        },
                        on: {
                          searchAssess: _vm.searchAssess,
                          detailPop: _vm.detailAssess,
                          removeAssess: _vm.removeAssess,
                        },
                        model: {
                          value: _vm.educationInfo.assessmentName,
                          callback: function ($$v) {
                            _vm.$set(_vm.educationInfo, "assessmentName", $$v)
                          },
                          expression: "educationInfo.assessmentName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                    },
                    [
                      _c("c-textarea", {
                        attrs: {
                          disabled: _vm.disabled,
                          editable: _vm.editable,
                          name: "educationOverview",
                          label: "교육총평",
                        },
                        model: {
                          value: _vm.educationInfo.educationOverview,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.educationInfo,
                              "educationOverview",
                              $$v
                            )
                          },
                          expression: "educationInfo.educationOverview",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-field", {
                        attrs: {
                          type: "user",
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          isUserAll: true,
                          label: "평가자",
                          name: "instructorInformation",
                        },
                        model: {
                          value: _vm.educationInfo.instructorInformation,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.educationInfo,
                              "instructorInformation",
                              $$v
                            )
                          },
                          expression: "educationInfo.instructorInformation",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          suffix: "원",
                          type: "number",
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          label: "집행예산",
                          name: "executionBudget",
                        },
                        model: {
                          value: _vm.educationInfo.executionBudget,
                          callback: function ($$v) {
                            _vm.$set(_vm.educationInfo, "executionBudget", $$v)
                          },
                          expression: "educationInfo.executionBudget",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-4 col-sm-4 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-select", {
                        attrs: {
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          type: "edit",
                          codeGroupCd: "EDU_EVAL_CD",
                          itemText: "codeName",
                          itemValue: "code",
                          name: "evaluationEvalCd",
                          label: "교육평가",
                        },
                        model: {
                          value: _vm.educationInfo.evaluationEvalCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.educationInfo, "evaluationEvalCd", $$v)
                          },
                          expression: "educationInfo.evaluationEvalCd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
          [
            _c(
              "c-table",
              {
                ref: "table",
                attrs: {
                  title: "교육내용",
                  columns: _vm.grid.columns,
                  gridHeight: _vm.grid.height,
                  data: _vm.educationInfo.eduSubjectList,
                  filtering: false,
                  columnSetting: false,
                  usePaging: false,
                  hideBottom: true,
                  noDataLabel: "교육내용이 없습니다.",
                  editable: _vm.editable && !_vm.disabled,
                  rowKey: "subjectNo",
                  selection: "multiple",
                },
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                label: "추가",
                                showLoading: false,
                                icon: "add",
                              },
                              on: { btnClicked: _vm.addItem },
                            })
                          : _vm._e(),
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.educationInfo.eduSubjectList.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                label: "제외",
                                showLoading: false,
                                icon: "remove",
                              },
                              on: { btnClicked: _vm.removeItem },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
          [
            _c(
              "c-card",
              { attrs: { title: "교육교재(교육과정 관련)" } },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-upload", {
                        attrs: { attachInfo: _vm.attachInfo, editable: false },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table2",
                attrs: {
                  title: "교육대상자",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  data: _vm.educationInfo.eduAttendeeList,
                  filtering: false,
                  checkClickFlag: false,
                  columnSetting: false,
                  usePaging: false,
                  merge: _vm.grid2.merge,
                  isExcelDown: false,
                  hideBottom: true,
                  noDataLabel: "교육대상자가 없습니다.",
                  editable: _vm.editable && !_vm.disabled,
                  rowKey: "attendeesNo",
                  selection: "multiple",
                },
                scopedSlots: _vm._u([
                  {
                    key: "customArea",
                    fn: function ({ props, col }) {
                      return [
                        col.name === "click"
                          ? [
                              _c(
                                "q-btn",
                                {
                                  attrs: {
                                    round: "",
                                    unelevated: "",
                                    size: "10px",
                                    color: "amber",
                                    icon: "search",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() => {
                                        ;(_vm.rowIndex = props.rowIndex),
                                          (_vm.electronSignature =
                                            props.row.electronSignature)
                                      }).apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "q-popup-proxy",
                                    {
                                      ref: "proxy1_" + props.rowIndex,
                                      attrs: { breakpoint: 400 },
                                    },
                                    [
                                      _c(col.component, {
                                        tag: "component",
                                        attrs: {
                                          popupParam: props.row,
                                          rowIndex: props.rowIndex,
                                          disabled: _vm.disabled,
                                        },
                                        on: { callback: _vm.callback },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              },
              [
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "일용직추가",
                                icon: "add",
                              },
                              on: { btnClicked: _vm.addAttendee1 },
                            })
                          : _vm._e(),
                        _vm.editable && !_vm.disabled
                          ? _c("c-btn", {
                              attrs: {
                                showLoading: false,
                                label: "사용자추가",
                                icon: "add",
                              },
                              on: { btnClicked: _vm.addAttendee2 },
                            })
                          : _vm._e(),
                        _vm.editable &&
                        !_vm.disabled &&
                        _vm.educationInfo.eduAttendeeList.length > 0
                          ? _c("c-btn", {
                              attrs: {
                                label: "제외",
                                showLoading: false,
                                icon: "remove",
                              },
                              on: { btnClicked: _vm.removeItem2 },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }